import { useDispatch } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import type { PreloadedState } from '@reduxjs/toolkit'
import { baseApi } from '@local/services/API/baseApi'
import anslutnaForetagReducer from '@local/Views/AnslutnaForetag/anslutnaForetagSlice'

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  ['anslutnaForetag']: anslutnaForetagReducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([baseApi.middleware]),
    preloadedState,
  })

export const store = setupStore()
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
