import React from 'react'
import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'

const CenteredSpinner = styled('div')(() => ({
  textAlign: 'center',
}))

const Spinner = ({
  centered,
  size = 40,
}: {
  centered?: boolean
  size?: number
}): JSX.Element =>
  centered ? (
    <CenteredSpinner>
      <CircularProgress size={size} />
    </CenteredSpinner>
  ) : (
    <CircularProgress size={size} />
  )

export default Spinner
