import React from 'react'
import { Stack, Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import { Warning } from '@mui/icons-material'
import { IAnslutnaForetagContent } from '@local/Views/AnslutnaForetag/AnslutnaForetag.types'

const ForetagEjAnslutet = (): JSX.Element => {
  const { sokresultatEjAnslutet } = usePickEpiContent<IAnslutnaForetagContent>()

  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <Warning />
        <Typography variant="h6">
          {sokresultatEjAnslutet.titel.heading}
        </Typography>
      </Stack>

      <Gutter offset_xs={16} />

      <HTMLMapper body={sokresultatEjAnslutet.brodtext.mainBody} />
    </>
  )
}

export default ForetagEjAnslutet
