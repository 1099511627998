import React, { useCallback } from 'react'
import { Button } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { FormikTouched, useFormikContext, setNestedObjectValues } from 'formik'
import { IAnslutnaForetagFormValues } from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/AnslutnaForetagForm.types'
import { isEmpty } from 'ramda'
import { setShouldGetAnslutnaForetag } from '@local/Views/AnslutnaForetag/anslutnaForetagSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import { styled } from '@mui/material/styles'
import { IAnslutnaForetagContent } from '@local/Views/AnslutnaForetag/AnslutnaForetag.types'

const StyledSubmitButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(0.5),
  },
  '& button': {
    width: '100%',
  },
}))

const FormSubmit = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { sok } = usePickEpiContent<IAnslutnaForetagContent>()

  const { validateForm, setTouched } =
    useFormikContext<IAnslutnaForetagFormValues>()

  const handleSubmit = useCallback(() => {
    void (async () => {
      const formErrors = await validateForm()

      if (isEmpty(formErrors)) {
        dispatch(setShouldGetAnslutnaForetag(true))
      } else {
        setTouched(
          setNestedObjectValues<FormikTouched<IAnslutnaForetagFormValues>>(
            formErrors,
            true
          )
        ).catch((err) => console.log(err))
      }
    })()
  }, [validateForm, setTouched, dispatch])

  return (
    <StyledSubmitButtonContainer>
      <Button color="primary" onClick={handleSubmit}>
        {sok.buttonText}
      </Button>
    </StyledSubmitButtonContainer>
  )
}

export default FormSubmit
