import React from 'react'
import { AlertProps, Grid, Alert as MuiAlert } from '@mui/material'

const Alert: React.FunctionComponent<React.PropsWithChildren<AlertProps>> = ({
  children,
  ...props
}): JSX.Element => (
  <Grid container>
    <Grid item maxWidth={752} flex="0 0 100%">
      <MuiAlert {...props}>{children}</MuiAlert>
    </Grid>
  </Grid>
)

export default Alert
