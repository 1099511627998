import * as Yup from 'yup'
import { IAnslutnaForetagContent } from '@local/Views/AnslutnaForetag/AnslutnaForetag.types'
import { IAnslutnaForetagFormValues } from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/AnslutnaForetagForm.types'
import { orgNummerRegex } from '@local/Utils/Helpers/regexes'
import { validateOrganizationNumber } from '@local/Utils/Helpers/Forms/Forms.helpers'

export const anslutnaForetagValidationSchema = (
  content: IAnslutnaForetagContent
): Yup.AnySchema<IAnslutnaForetagFormValues> =>
  Yup.object().shape({
    organisationsnummer: Yup.string()
      .required(content.sok.orgnummerIsRequired)
      .trim()
      .matches(orgNummerRegex, content.sok.orgnummerHasInvalidFormat)
      .test('validateOrgnummer', content.sok.orgnummerIsInvalid, (orgNummer) =>
        validateOrganizationNumber(orgNummer)
      )
      .typeError(content.sok.orgnummerIsInvalid),
  })

export const initialAnslutnaForetagValues: IAnslutnaForetagFormValues = {
  organisationsnummer: '',
}
