import React from 'react'
import { HTMLMapperCCL, ElementMap } from '@trr/html-mapper'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledHTMLMapper = styled('div')(({ theme }) => ({
  'ul[class*="List-module_List"]': {
    marginBottom: 0,

    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  'article[class*="highlightbox"]': {
    maxWidth: 752,
  },
}))

const allowedTags = ['p']

const elements: ElementMap = {
  p: ({ renderChildren }) => (
    <Typography variant="body1" gutterBottom={false}>
      {renderChildren()}
    </Typography>
  ),
}

const HTMLMapper = ({ body }: { body: string }) => (
  <StyledHTMLMapper>
    <HTMLMapperCCL body={body} elements={elements} allowedTags={allowedTags} />
  </StyledHTMLMapper>
)

export default HTMLMapper
