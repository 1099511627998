import React from 'react'
import { Provider } from 'react-redux'
import { store } from '@local/Store/configureStore'
import AnslutnaForetag from '@local/Views/AnslutnaForetag'
import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { GTMTracker } from '@trr/gtm-tracking'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppShellDataProvider value={appShellData}>
          <GTMTracker mfName="anslutna-foretag">
            <AnslutnaForetag />
          </GTMTracker>
        </AppShellDataProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
