import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IResultatProps } from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/ForetagResultat/ForetagResultat.types'
import HTMLMapper from '@local/Components/HTMLMapper'
import { CheckCircle } from '@mui/icons-material'
import { useUser } from '@trr/app-shell-data'
import { isEmpty, isNil } from 'ramda'
import { IAnslutnaForetagContent } from '@local/Views/AnslutnaForetag/AnslutnaForetag.types'

const ForetagAnslutet = ({ result }: IResultatProps): JSX.Element => {
  const user = useUser()
  const { sokresultatAnslutet, loggaIn } =
    usePickEpiContent<IAnslutnaForetagContent>()

  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <CheckCircle color="secondary" />
        <Typography variant="h6">
          {replaceEpiVariables(sokresultatAnslutet.titel.heading, {
            companyName:
              result?.name ?? sokresultatAnslutet.titel.companyNamePlaceholder,
          })}
        </Typography>
      </Stack>

      <Gutter offset_xs={16} />

      <HTMLMapper body={sokresultatAnslutet.brodtext.mainBody} />

      {(isEmpty(user) || isNil(user)) && (
        <>
          <Gutter offset_xs={64} />

          <Typography variant="h5">{loggaIn.heading}</Typography>

          <Gutter offset_xs={24} />

          <Typography variant="body1">{loggaIn.description}</Typography>

          <Gutter offset_xs={24} />

          <Button href={loggaIn.url}>{loggaIn.buttonLabel}</Button>
        </>
      )}
    </>
  )
}

export default ForetagAnslutet
